import axios from 'axios'
import store from '../store'
//import * as utils from '@/utils'

const URL_SERVIDOR_DESARROLLO = 'http://localhost:5000'; //https://simfc.mexicomfc.net
export const ES_DESARROLLO = (location.hostname === "localhost" || location.hostname === "127.0.0.1" || location.hostname.startsWith("192"));
export const API_URL = ES_DESARROLLO ? `${URL_SERVIDOR_DESARROLLO}/api` : '/api';
export const PDF_URL = ES_DESARROLLO ? `${URL_SERVIDOR_DESARROLLO}/api/formatos` : '/api/formatos';
export const ARCHIVOS_URL = ES_DESARROLLO ? `${URL_SERVIDOR_DESARROLLO}/api/archivos` : '/api/archivos';
export const Constantes = {
    TIPO_MATRIMONIO: 1,
    TIPO_JOVEN: 2,
    TIPO_MARE: 3,
    TIPO_AE: 4,
    TIPO_ADOLESCENTE: 5,
    TIPOS_MEMBRESIA: ['', 'Matrimonios', 'Jóvenes', 'Mares', 'Asistentes eclesiales', 'Adolescentes'],
    TIPOS_EVENTOS: ['', 'Capacitaciones', 'Momentos Fuertes', 'Reuniones Generales', 'Reuniones Funcionales', '', 'Servicios Institucionales de Área II', '', '', 'Nacional'],
    JERARQUIAS: ['','','','Equipo','Promotor','Zonal','Sector','Diocesano','Regional','Nacional'],
    EQUIPEROS: [4, 12, 985, 982],
    PROMOTORES: [14, 13, 994, 983],
    ESTATUS_ALTA: 20,
    PROMOTORES_ADOLESCENTES: [983, 984],
    EQUIPERO_JOVEN: 985,
    JERARQUIA_SECTOR: 6,
    JERARQUIA_DIOCESANO: 7,
};
export const TIEMPO_SESION = 10;
const api = {
    get(url) {
        return axios.get(`${API_URL}/${url}`);
    },
    iniciarSesion(datos) {
        return axios.post(`${API_URL}/sesion/iniciar/`, datos);
    },
    renovarSesion() {
        return axios.post(`${API_URL}/sesion/renovar/`);
    },
    //////////////////////////////////////////////
    asistentesActa(datos) {
        return axios.post(`${API_URL}/acta/asistentes/`, datos);
    },
    listarActasTipo(tipo, jerarquia) {
        return axios.get(`${API_URL}/acta/tipos/${tipo}/${jerarquia}/`);
    },
    listarActas(tipo, jerarquia, busqueda) {
        return axios.get(`${API_URL}/acta/listar/${tipo}/${jerarquia}/`, { params: busqueda });
    },
    obtenerActa(id) {
        return axios.get(`${API_URL}/acta/obtener/${id}/`);
    },
    guardarActa(datos, evento = null) {
        let vars = {};
        if (evento != null) {
            vars.onUploadProgress = evento;
        }
        return axios.post(`${API_URL}/acta/guardar/`, datos, vars);
    },
    listarActasRegion(busqueda) {
        return axios.get(`${API_URL}/acta/region/listar/`, { params: busqueda });
    },
    obtenerActaRegion(id) {
        return axios.get(`${API_URL}/acta/region/obtener/${id}/`);
    },
    guardarActaRegion(datos) {
        return axios.post(`${API_URL}/acta/region/guardar/`, datos);
    },
    //////////////////////////////////////////////
    listarFormaciones(tipo) {
        return axios.get(`${API_URL}/evento/formaciones/${tipo}/`);
    },
    listarEventos(tipo, busqueda) {
        return axios.get(`${API_URL}/evento/listar/${tipo}/`, { params: busqueda });
    },
    obtenerEventoQr(id) {
        return axios.get(`${API_URL}/evento/obtenerqr/${id}/`);
    },
    obtenerEvento(id) {
        return axios.get(`${API_URL}/evento/obtener/${id}/`);
    },
    guardarEvento(datos, evento = null) {
        let vars = {};
        if (evento != null) {
            vars.onUploadProgress = evento;
        }
        return axios.post(`${API_URL}/evento/guardar/`, datos, vars);
    },
    eliminarEvento(id) {
        return axios.delete(`${API_URL}/evento/eliminar/evento/${id}/`);
    },
    listarEventosNo(tipo, busqueda) {
        return axios.get(`${API_URL}/evento/listarno/`, { params: busqueda });
    },
    obtenerEventoNo(id) {
        return axios.get(`${API_URL}/evento/obtenerno/${id}/`);
    },
    guardarEventoNo(datos) {
        return axios.post(`${API_URL}/evento/guardarno/`, datos);
    },
    eliminarEventoNo(id) {
        return axios.delete(`${API_URL}/evento/eliminar/eventono/${id}/`);
    },

    registrarBuscarParticipanteNombre(tipo, nombre) {
        return axios.post(`${API_URL}/evento/registrar/buscar/${tipo}/${nombre}/`);
    },
    registrarParticipanteId(evento_id, membresia_id) {
        return axios.post(`${API_URL}/evento/registrar/id/${evento_id}/${membresia_id}/`);
    },
    registrarParticipanteQr(evento_id, qr) {
        return axios.post(`${API_URL}/evento/registrar/qr/${evento_id}/${qr}/`);
    },
    registrarParticipanteIdd(evento_id, diocesis_id, tipo_membresia_id, membresia_id) {
        return axios.post(`${API_URL}/evento/registrar/idd/${evento_id}/${diocesis_id}/${tipo_membresia_id}/${membresia_id}/`);
    },
    registrarParticipanteNo(evento_id, datos) {
        return axios.post(`${API_URL}/evento/registrar/no/${evento_id}/`, datos);
    },
    eliminarParticipante(id) {
        return axios.post(`${API_URL}/evento/eliminar/id/${id}/`);
    },
    eliminarParticipanteNo(id) {
        return axios.post(`${API_URL}/evento/eliminar/no/${id}/`);
    },
    //////////////////////////////////////////////
    listarEquiperos(busqueda) {
        return axios.get(`${API_URL}/membresia/listarequiperos/`, { params: busqueda });
    },
    listarUsuarios(busqueda) {
        return axios.get(`${API_URL}/membresia/listarusuarios/`,  { params: busqueda });
    },
    actualizarContrasena(datos) {
        return axios.post(`${API_URL}/actualizar/contrasena/`, datos);
    },
    //////////////////////////////////////////////
    listarEstatusMembresia() {
        return axios.get(`${API_URL}/membresia/estatus/listar/`);
    },
    obtenerMembresia(id) {
        return axios.get(`${API_URL}/membresia/obtener/${id}/`);
    },
    guardarMembresia(datos) {
        return axios.post(`${API_URL}/membresia/guardar/`, datos);
    },

    listarMembresia(busqueda) {
        return axios.get(`${API_URL}/membresia/listar/`, { params: busqueda });
    },

    listarMembresiaTipo(tipo, busqueda) {
        return axios.get(`${API_URL}/membresia/listar/${tipo}/`, { params: busqueda });
    },

    listarTipoMembresias() {
        return axios.get(`${API_URL}/membresia/tipo_membresia/listar/`);
    },
    listarJerarquias() {
        return axios.get(`${API_URL}/membresia/jerarquia/listar/`);
    },

    reporteBajasTipo(tipo, busqueda) {
        return axios.get(`${API_URL}/membresia/bajas/${tipo}/`, { params: busqueda });
    },
    //////////////////////////////////////////////
    listarResumenCBF(tipo, busqueda) {
        return axios.get(`${API_URL}/membresia/listarresumen/${tipo}/`, { params: busqueda });
    },    
    listarMembresiaCBF(tipo, busqueda) {
        return axios.get(`${API_URL}/membresia/listarcbf/${tipo}/`, { params: busqueda });
    },    
    listarDirServicios(busqueda) {
        return axios.get(`${API_URL}/membresia/listarDirServicios/`, { params: busqueda });
    },    
    obtenerReunionCBF(id) {
        return axios.get(`${API_URL}/cbf/reunion/obtener/${id}/`);
    },
    guardarComentariosReunionCBF(datos) {
        return axios.post(`${API_URL}/cbf/reunion/comentarios/`, datos);
    },
    listarReunionesCBF(busqueda) {
        return axios.get(`${API_URL}/membresia/listarReunionesCBF/`, { params: busqueda });
    },    
    listarProxReunionesCBF(busqueda) {
        return axios.get(`${API_URL}/membresia/listarProxReunionesCBF/`, { params: busqueda });
    },    
    guardarEquipos(datos) {
        return axios.post(`${API_URL}/cbf/equipos/guardar/`, datos);
    },
    listarMembresiaS09(tipo, busqueda) {
        return axios.get(`${API_URL}/membresia/listars09/${tipo}/`, { params: busqueda });
    },    
    guardarOfrenda(datos) {
        return axios.post(`${API_URL}/cbf/ofrenda/guardar/`, datos);
    },
    //////////////////////////////////////////////
    listarEquiposCBF() {
        return axios.get(`${API_URL}/cbf/mis/equipos/`);
    },
    listarTemasCBF(equipo) {
        return axios.get(`${API_URL}/cbf/temas/${equipo}/`);
    },
    guardarReunion(reunion) {
        return axios.post(`${API_URL}/cbf/guardar/`, reunion);
    },
    //////////////////////////////////////////////
    listarNiveles(tipo_membresia) {
        if (tipo_membresia) {
            return axios.get(`${API_URL}/cbf/nivel/listar/${tipo_membresia}/`);
        } else {
            return axios.get(`${API_URL}/cbf/nivel/listar/`);
        }
    },    
    //////////////////////////////////////////////
    listarFunciones() {
        return axios.get(`${API_URL}/permisos/funcion/listar/`);
    },
    listarFuncionPermisos(id) {
        return axios.get(`${API_URL}/permisos/funcion/permisos/${id}/`);
    },
    guardarFuncion(datos) {
        return axios.post(`${API_URL}/permisos/funcion/guardar/`, datos);
    },
    eliminarFuncion(id) {
        return axios.delete(`${API_URL}/permisos/funcion/eliminar/${id}`);
    },
    //////////////////////////////////////////////

    listarRolesTodos() {
        return axios.get(`${API_URL}/permisos/rol/todos/`);
    },

    listarRoles(tipo) {
        if (tipo) {
            return axios.get(`${API_URL}/permisos/rol/listar/${tipo}/`);
        } else {
            return axios.get(`${API_URL}/permisos/rol/listar/`);
        }
    },

    listarRolesDetalle( busqueda) {
        return axios.get(`${API_URL}/permisos/rol/listar_detalle/`, { params: busqueda });
    },
    guardarRol(datos) {
        return axios.post(`${API_URL}/permisos/rol/guardar/`, datos);
    },
    eliminarRol(id) {
        return axios.delete(`${API_URL}/permisos/rol/eliminar/${id}`);
    },
    //////////////////////////////////////////////
    listarReportes(tipo) {
        return axios.get(`${API_URL}/reporte/listado/${tipo}/`);
    },     
    ejecutarReporte(reporte_id, busqueda) {
        return axios.get(`${API_URL}/reporte/consulta/${reporte_id}/`, { params: busqueda });
    },     
    //////////////////////////////////////////////
    reporteD01(diocesis_id) {
        return axios.get(`${API_URL}/reporte/d01/${diocesis_id}/`);
    },     
    reporteS03(sector_id) {
        return axios.get(`${API_URL}/reporte/s03/${sector_id}/`);
    },     
    reporteS04(sector_id) {
        return axios.get(`${API_URL}/reporte/s04/${sector_id}/`);
    },     
    reporteS07(sector_id) {
        return axios.get(`${API_URL}/reporte/s07/${sector_id}/`);
    },   
    //////////////////////////////////////////////
    evaluacionListarPreguntas(rol) {
        return axios.post(`${API_URL}/evaluacion/preguntas/${rol}/`);
    },
    //Sin sesión
    evaluacionContestada(datos) {
        return axios.post(`${API_URL}/evaluacion/contestada/`, datos);
    },
    //Con sesión   
    evaluacionContestadas(datos) {
        return axios.post(`${API_URL}/evaluacion/contestadas/`, datos);
    },   
    //Sin sesión
    evaluacionGuarda(datos) {
        return axios.post(`${API_URL}/evaluacion/guarda/`, datos);
    },   
    //Con sesión   
    evaluacionGuardar(datos) {
        return axios.post(`${API_URL}/evaluacion/guardar/`, datos);
    },   
    evaluacionLoginFechas(datos) {
        return axios.post(`${API_URL}/evaluacion/sesion/fechas/`, datos);
    },
    evaluacionDatosEquipero(datos) {
        return axios.post(`${API_URL}/evaluacion/datos/equipero/`, datos);
    },
    evaluacionDatosRol(rol) {
        return axios.post(`${API_URL}/evaluacion/datos/rol/${rol}/`);
    },  
    //////////////////////////////////////////////
    listarRegiones() {
        return axios.get(`${API_URL}/estructura/region/listar/`);
    },   
    listarDiocesis(region) {
        if (region) {
            return axios.get(`${API_URL}/estructura/diocesis/listar/${region}/`);
        } else{
            return axios.get(`${API_URL}/estructura/diocesis/listar/`);
        }
    },   
    listarSectores(diocesis) {
        if (diocesis) {
            return axios.get(`${API_URL}/estructura/sector/listar/${diocesis}/`);
        } else {
            return axios.get(`${API_URL}/estructura/sector/listar/`);
        }
    },
    listarSectoresDiocesis(diocesis) {
        return axios.get(`${API_URL}/estructura/sector/listar/${diocesis}/`);
    },
    listarSectoresDetalle(busqueda) {
        return axios.get(`${API_URL}/estructura/sector/listar_detalle/`, { params: busqueda});
    },
    guardarSector(datos) {
        return axios.post(`${API_URL}/estructura/sector/guardar/`, datos);
    },
    eliminarSector(id) {
        return axios.delete(`${API_URL}/estructura/sector/eliminar/${id}`);
    },
    getMotivosBaja() {
        return axios.get(`${API_URL}/membresia/motivos_baja/`);
    },
    bajaMembresia(membresia_baja) {
        return axios.post(`${API_URL}/membresia/baja_temporal/`, membresia_baja);
    },
    confirmarBajaMembresia(membresia_baja){
        return axios.post(`${API_URL}/membresia/baja_definitiva/`, membresia_baja);
    },
    //////////////////////////////////////////////
    listarDiocesisDetalle(busqueda) {
        return axios.get(`${API_URL}/estructura/diocesis/listar_detalle/`, { params: busqueda });
    },
    guardarDiocesis(datos) {
        return axios.post(`${API_URL}/estructura/diocesis/guardar/`, datos);
    },
    eliminarDiocesis(id) {
        return axios.delete(`${API_URL}/estructura/diocesis/eliminar/${id}`);
    },
    //////////////////////////////////////////////
    listarCiclos(busqueda) {
        return axios.get(`${API_URL}/ciclo/listar/`, { params: busqueda });
    },
    guardarCiclo(datos) {
        return axios.post(`${API_URL}/ciclo/guardar/`, datos);
    },
    eliminarCiclo(id) {
        return axios.delete(`${API_URL}/ciclo/eliminar/${id}`);
    },
    //////////////////////////////////////////////
    obtenerCicloVigente() {
        return axios.get(`${API_URL}/registro/ciclo/vigente/`);
    },
    listarRegistroEstados() {
        return axios.get(`${API_URL}/registro/estado/listar/`);
    },
    listarRegistroMunicipios(estado) {
        return axios.get(`${API_URL}/registro/municipio/${estado}/listar/`);
    },
    listarRegistroLocalidades(municipio) {
        return axios.get(`${API_URL}/registro/localidad/${municipio}/listar/`);
    },
    listarRegistroGradosEstudio() {
        return axios.get(`${API_URL}/registro/grado_estudio/listar/`);
    },
    listarRegistroRolesEquiperos() {
        return axios.get(`${API_URL}/registro/roles/equiperos/`);
    },
    listarRegistroTiposMembresia() {
        return axios.get(`${API_URL}/registro/tipo_membresia/listar/`);
    },
    listarRegistroDiocesis() {
        return axios.get(`${API_URL}/registro/diocesis/listar/`);
    },
    listarRegistroSectores(diocesis) {
        return axios.get(`${API_URL}/registro/sector/${diocesis}/listar/`);
    },
    registroConsultarFechas(datos) {
        return axios.post(`${API_URL}/registro/registro/fechas/id/`, datos);
    },
    registroNacionalConsultarFechas(datos) {
        return axios.post(`${API_URL}/registro/registro/fechas/idnacional/`, datos);
    },
    registroConsultarQR(qr) {
        return axios.post(`${API_URL}/registro/registro/qr/${qr}/`);
    },
    registroLogin(datos) {
        return axios.post(`${API_URL}/registro/registro/sesion/iniciar/`, datos);
    },
    registroLoginFechas(datos) {
        return axios.post(`${API_URL}/registro/registro/sesion/fechas/`, datos);
    },
    registroNuevoDatos(datos) {
        return axios.post(`${API_URL}/registro/registro/sesion/nuevo/`, datos);
    },
    guardarRegistro(datos) {
        return axios.post(`${API_URL}/registro/membresia/guardar/`, datos);
    },
    //////////////////////////////////////////////
    listarBitacora(busqueda) {

        return axios.get(`${API_URL}/bitacora/listar/`, { params: busqueda });
    },
    listarErrores(busqueda) {
        return axios.get(`${API_URL}/errores/listar/`, { params: busqueda });
    },
    token() {
        let jwt = store.getters.token

        return { headers: { Authorization: `Bearer: ${jwt}` } }
    },

}
//var _controlTiempo = null;
axios.interceptors.request.use((config) => {
    let jwt = store.getters.token;
    config.headers.Authorization = `Bearer: ${jwt}`;
    /*if (!config.url.includes('/sesion/')) {
        if (utils.tiempoMinimoPaso(jwt)) {
            if (_controlTiempo != null) {
                clearTimeout(_controlTiempo);
            }
            _controlTiempo = setTimeout(() => store.dispatch("renovarSesion"), 5000);
        }
    }*/
    return config;
});
export default api;