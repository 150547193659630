<template>
  <div>
    <h4><font-awesome-icon icon="list" /> Evaluación de reunión</h4>
    <div class="card">
      <h6 class="card-header">Reunión {{ tema.nom_tema }}</h6>
      <div class="card-body">
        <div class="form-row">

          <div class="form-group col-md-3">
            <label for="fecha_ini">Fecha:</label>
            <span class="form-info">{{ reunion.fecha }}</span>
          </div>
          <div class="form-group col-md-3">

            <label>Anfitrión:</label>
            <label class="form-info">
              {{ membresias.filter(m => m.membresia_id == reunion.anfitrion_membresia_id)[0].nombre_completo }}
            </label>
          </div>
          <div class="form-group col-md-3">
            <label for="hora_ini">Hora programada:</label>
            <span class="form-info">{{ reunion.hora_cita }}</span>
          </div>
          <div class="form-group col-md-3">
            <label for="hora_ini">Hora inicio:</label>
            <span class="form-info">{{ reunion.hora_inicio }}</span>
          </div>

          <div class="form-group col-md-3">
            <label for="hora_fin">Hora de término:</label>
            <span class="form-info">{{ reunion.hora_termino }}</span>
          </div>

          <div class="form-group col-md-3">
            <label for="fecha_ini">Fecha próxima reunión:</label>
            <span class="form-info">{{ reunion.fecha_siguiente }}</span>

          </div>
          <div class="form-group col-md-3">
            <label for="hora_siguiente">Hora siguiente reunión:</label>
            <span class="form-info">{{ reunion.hora_siguiente }}</span>
          </div>
          <div class="form-group col-md-3">

            <label>Siguiente anfitrión:</label>
            <label class="form-info">
              {{ membresias.filter(m => m.membresia_id == reunion.siguiente_membresia_id)[0].nombre_completo }}
            </label>
          </div>
          <div class="form-group col-md-6">
            <label for="observaciones">Accion sugerida</label>
            <span class="form-info-texto">{{ reunion.accion_sugerida }}</span>
          </div>
          <div class="form-group col-md-6">
            <label for="observaciones">Observaciones</label>
            <span class="form-info-texto">{{ reunion.observaciones }}</span>
          </div>
          <div class="form-group col-md-6">
            <label for="observaciones">Necesidades</label>
            <span class="form-info-texto">{{ reunion.necesidades }}</span>
          </div>
          <b-button @click="showModal = true">Rangos de evaluación</b-button>
        </div>


        <div class="form-row" v-for="eva in evaluaciones" :key="eva.membresia_id">
          <div class="col-12">
            <table class="table table-bordered">
              <tr>
                <th>{{ eva.nombre_completo }}</th>
                <th> {{ tipo_membresia_id == Constantes.TIPO_MATRIMONIO ? 'El' : tipo_membresia_id ==
                  Constantes.TIPO_MARE ? 'Ella' : 'Jóven' }}</th>
                <th v-show="tipo_membresia_id == Constantes.TIPO_MATRIMONIO">Ella</th>
              </tr>
              <tr>
                <td><label>Puntualidad</label></td>
                <td>
                  <label>{{ eva.puntualidad1 }}</label>
                </td>
                <td v-show="tipo_membresia_id == Constantes.TIPO_MATRIMONIO">
                  <label>{{ eva.puntualidad2 }} </label>
                </td>
              </tr>
              <tr>
                <td><label>Estudio</label></td>
                <td>
                  <label>{{ eva.estudio1 }}</label>
                </td>
                <td v-show="tipo_membresia_id == Constantes.TIPO_MATRIMONIO">
                  <label>{{ eva.estudio2 }}</label>
                </td>
              </tr>
              <tr>
                <td><label>Participación</label></td>
                <td>
                  <label>{{ eva.participacion1 }}</label>
                </td>
                <td v-show="tipo_membresia_id == Constantes.TIPO_MATRIMONIO">
                  <label>{{ eva.participacion2 }}</label>
                </td>
              </tr>
              <tr>
                <td><label>Acción sugerida</label></td>
                <td>
                  <label>{{ eva.accionsugerida1 }}</label>
                </td>
                <td v-show="tipo_membresia_id == Constantes.TIPO_MATRIMONIO">
                  <label>{{ eva.accionsugerida2 }}</label>
                </td>
              </tr>
              <tr v-if="eva.tema_familia">
                <td>
                  <label>Tema en familia </label>
                </td>
                <td>
                  <label>{{ eva.familia1 }}</label>
                </td>
                <td v-show="tipo_membresia_id == Constantes.TIPO_MATRIMONIO">
                  <label>{{ eva.familia2 }}</label>
                </td>
              </tr>
            </table>
          </div>

        </div>
      </div>
    </div>
    <br />
    <div class="card">
      <h6 class="card-header">Comentarios del Zonal o Área I</h6>
      <div class="card-body">
        <form @submit.prevent="guardar">
          <div class="form-row">
            <div class="form-group col-md-12">
              <label>Comentarios del Zonal o Área I</label>
              <textarea v-model="comentarios" class="form-control" />
              <div class="errores" v-if="!$v.comentarios.required">
                Requerido
              </div>
            </div>
          </div>
          <br />
          <div class="text-center botones-accion">
            <button class="btn btn-primary" type="submit">Guardar</button>
            <button class="btn btn-secondary" v-on:click.stop.prevent="$router.go(-1)">
              Regresar
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import api from "@/api";
import { Constantes } from "@/api";
import { required } from "vuelidate/lib/validators";
import { API_URL } from "@/api";

export default {
  name: "CBFReunion",
  components: {},
  data() {
    return {
      API_URL: API_URL,
      Constantes: Constantes,
      puntos: [0, 5, 6, 7, 8, 9, 10],
      tipo_membresia_id: 0,
      membresias: [],
      comentarios: null,
      tema: {},
      reunion: {},
      evaluaciones: []
    };
  },
  validations() {
    return {
      comentarios: {
        required,
      },
    }
  },
  methods: {

    obtenerReunion: function (id) {
      api.obtenerReunionCBF(id).then((resp) => {
        console.log(resp.data);
        let tema = resp.data.temas[0];
        this.membresias = resp.data.membresias;
        this.tema = tema.tema;
        this.reunion = tema.reunion;
        this.evaluaciones = tema.evaluaciones;
        this.tipo_membresia_id = this.membresias[0].tipo_membresia_id;
        this.comentarios = this.reunion.comentarios;

        let ideq = {};
        this.membresias.forEach(e => {
          ideq[e.membresia_id] = e;
        });

        this.evaluaciones.forEach(eva => {
          eva.nombre_completo = ideq[eva.membresia_id].nombre_completo;
        });

        this.evaluando = true;
      });
    },
    guardar() {
      this.$v.comentarios.$touch();
      if (!this.$v.comentarios.$invalid) {
        api.guardarComentariosReunionCBF({comentarios: this.comentarios, reunion_cbf_id: this.reunion.reunion_cbf_id}).then((resp) => {
          if (resp.data.respuesta) {
            this.$parent.mensaje("Comentarios guardados");
          } else {
            this.$parent.error("Error al guardar la información " + resp.data.error);
          }
        })
        .catch((error) => {
            this.$parent.error("Error al guardar la información " + error);
          });
      } else {
        this.$parent.error("Información incompleta");
      }
    }
  },
  computed: {},
  mounted: function () {
    this.obtenerReunion(this.$route.params.id);
  },
};
</script>
<style scoped>
.acuerdo-number {
  font-size: 1.2em;
  /* Tamaño de fuente más grande */
  color: #007bff;
  /* Color de resaltado, puedes ajustarlo a tu preferencia */
  font-weight: bold;
  /* Texto en negrita */
}

.underlined-text {
  text-decoration: underline;
  /* Subrayar el texto */
}
</style>
