<template>
  <b-card header="Filtros">
        <b-form-row>
          <b-col md="4" v-if="mostrar.region">
            <b-form-group label="Región">
              <b-form-select
                v-model="busqueda.region_id"
                :options="listadoRegiones"
                value-field="region_id"
                text-field="nom_region"
                v-on:change="filtrarDiocesis"
              >
                <b-form-select-option :value="null">Todos</b-form-select-option>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col md="4" v-if="mostrar.diocesis">
            <b-form-group label="Diócesis">
              <b-form-select
                v-model="busqueda.diocesis_id"
                :options="listadoDiocesisFiltro"
                value-field="diocesis_id"
                text-field="nom_diocesis"
                v-on:change="actualizarSectores"
              >
                <b-form-select-option :value="null">Todos</b-form-select-option>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col md="4" v-if="mostrar.sector">
            <b-form-group label="Sector">
              <b-form-select
                v-model="busqueda.sector_id"
                :options="listadoSectores"
                value-field="sector_id"
                text-field="nom_sector"
                @change="buscar"
              >
                <b-form-select-option :value="null">Todos</b-form-select-option>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col sm="4" v-if="mostrar.ciclo" >
            <b-form-group label="Ciclo">
              <b-form-select
                v-model="busqueda.ciclo_id"
                :options="listadoCiclos"
                value-field="ciclo_id"
                text-field="nom_ciclo"
                @change="buscar"
              >
                <b-form-select-option :value="null">Todos</b-form-select-option>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col sm="4" v-if="mostrar.rol" >
            <b-form-group label="Rol">
              <b-form-select
                v-model="busqueda.rol_id"
                :options="listadoRoles"
                value-field="rol_id"
                text-field="nom_rol"
                @change="buscar"
              >
                <b-form-select-option :value="null">Todos</b-form-select-option>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col sm="4" v-if="mostrar.nivel">
            <b-form-group label="Nivel" >
              <b-form-select
                v-model="busqueda.nivel_id"
                :options="listadoNiveles"
                value-field="nivel_id"
                text-field="nom_nivel"
                @change="buscar"
              >
                <b-form-select-option :value="null">Todos</b-form-select-option>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col sm="4" v-if="mostrar.mes">
            <b-form-group label="Mes" >
              <b-form-select
                v-model="busqueda.mes"
                :options="listadoMeses"
                value-field="mes"
                text-field="nom_mes"
                @change="buscar"
              >
                <b-form-select-option :value="null">Todos</b-form-select-option>
              </b-form-select>
            </b-form-group>
          </b-col>
        </b-form-row>       
    </b-card>
</template>
<script >
import api from "@/api";
import {  requiredIf } from "vuelidate/lib/validators";
import { numeros, letras } from "@/libs/validadores";
export default {
  name: "FiltrosReportes",
  props: {
    value: {
        type: Object,
        required: true
      },
    mostrar: {
      type: Object,
      default(props) {
        const m = {
          region: false,
          diocesis: false,
          sector: false,
          ciclo: false,
          nivel: false,
          rol: false,
          mes: false,
        }
        return { ...m, ...props }
    }
    },
    mostrarEstado: {
      type: Boolean,
      default: true,
    },
    tipoMembresia: Number,
  },
  data() {
    return {
      busqueda: this.value,
      listadoRegiones: [],
      listadoDiocesis: [],
      listadoDiocesisFiltro: [],
      listadoSectores: [],
      listadoRoles: [],
      listadoNiveles: [],
      listadoCiclos: [],
      listadoMeses: [{mes:1, nom_mes:'Enero'},{mes:2, nom_mes:'Febrero'},{mes:3, nom_mes:'Marzo'},
                     {mes:4, nom_mes:'Abril'},{mes:5, nom_mes:'Mayo'},{mes:6, nom_mes:'Junio'},
                     {mes:7, nom_mes:'Julio'},{mes:8, nom_mes:'Agosto'},{mes:9, nom_mes:'Septiembre'},
                     {mes:10, nom_mes:'Octubre'},{mes:11, nom_mes:'Noviembre'},{mes:12, nom_mes:'Diciembre'},]
    };
  },

  validations() {
    return {
      busquedaMembresia: { 
        id: { numeros, required: requiredIf ((m)=>m.tipo=='buscar_id')} ,
        nombre: { letras, required: requiredIf ((m)=>m.tipo=='buscar_nombre')} ,
      }
      
    };
  },
  methods: {

    buscar() {
      const busqueda = { ...this.busqueda };
      
      this.$store.dispatch("guardarFiltros", busqueda);
      this.$emit('input', busqueda);
    },
    limpiarBusqueda() {
      this.busqueda = {
        region_id: null,
        diocesis_id: null,
        sector_id: null,
        rol_id: null,
        nivel_id: null,
        ciclo_id: null,
        mes: new Date().getMonth() + 1
      };
      this.buscar();
    },
    actualizarRegiones() {
      api.listarRegiones().then((r) => {
        if (Array.isArray(r.data)) {
          this.listadoRegiones = r.data;
        } else {
          this.listadoRegiones = [];
        }
      });
    },
    actualizarDiocesis() {
      api.listarDiocesis().then((r) => {
        if (Array.isArray(r.data)) {
          this.listadoDiocesis = r.data;
          this.listadoDiocesisFiltro = r.data;
        } else {
          this.listadoDiocesis = [];
        }
      });
    },
    filtrarDiocesis() {

      let existe = false;
      this.listadoDiocesisFiltro = this.listadoDiocesis.filter(d => {
        const agregar = this.busqueda.region_id == null || this.busqueda.region_id == d.region_id;
        if (agregar) {
          if (d.diocesis_id == this.busqueda.diocesis_id) {
            existe = true;
          }
        }
        return agregar;
      });
      if (!existe) {
        this.busqueda.diocesis_id = null;
        this.actualizarSectores();
      }
      this.buscar();
    },
    actualizarSectores() {
      this.busqueda.sector_id = null;
      if (this.busqueda.diocesis_id != null) {
        api.listarSectoresDiocesis(this.busqueda.diocesis_id).then((r) => {
          if (Array.isArray(r.data)) {
            this.listadoSectores = r.data;
          } else {
            this.listadoSectores = [];
          }
        });
      } else {
        this.listadoSectores = [];
      }
      this.buscar();

    },
    actualizarRoles() {
      api.listarRoles(this.tipoMembresia).then((r) => {
        if (Array.isArray(r.data)) {
          this.listadoRoles = r.data;
        } else {
          this.listadoRoles = [];
        }
      });
    },
    actualizarNiveles() {
      api.listarNiveles(this.tipoMembresia).then((r) => {
        if (Array.isArray(r.data)) {
          this.listadoNiveles = r.data;
        } else {
          this.listadoNiveles = [];
        }
      });
    },
    actualizarCiclos() {
      api.listarCiclos().then((r) => {
        if (Array.isArray(r.data)) {
          this.listadoCiclos = r.data;
          if (!this.busqueda.ciclo_id) {
            const ciclo = this.listadoCiclos.find(c => c.ciclo_vigente);
            this.busqueda.ciclo_id = ciclo.ciclo_id;
            this.cicloActual = ciclo.ciclo_id;
          }
        } 
        
      });
    }, 
    inicializarBusqueda(ciclo_id) {
      if (!this.$store.getters.filtros) {
        this.$store.dispatch('consultarFiltros')
      }
      const val = this.$store.getters.filtros;
      this.busqueda = {
        region_id: val.region_id ?? null,
        diocesis_id: val.diocesis_id ?? null,
        sector_id: val.sector_id ?? null,
        rol_id: val.rol_id ?? null,
        nivel_id: val.nivel_id ?? null,
        ciclo_id: val.ciclo_id ?? ciclo_id,
        mes: val.mes ?? null,
      };
      this.buscar();
      //this.$emit('input', { ...this.busqueda });
    },
    inicializa() {
      this.inicializarBusqueda();
      this.actualizarRegiones();
      this.actualizarDiocesis();
      if (this.mostrar.ciclo) {
        this.actualizarCiclos();
      }
      if (this.mostrar.nivel) {
        this.actualizarNiveles();
      }
      if (this.mostrar.rol) {
        this.actualizarRoles();
      }
    }
  },
  mounted() {
    this.inicializa()
  },
}

</script>

<style>


</style>