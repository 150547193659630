import Evalua from '@/views/Evalua.vue';
import EvaluaPromotor from '@/views/EvaluaPromotor.vue';

export default [
  {
    path: '/evalua/:rol',
    name: 'Evalua',
    component: Evalua
  },
  {
    path: '/evalua-promotor',
    name: 'EvaluaPromotor',
    component: EvaluaPromotor
  },
  
];
