<template>
    <div>
      <div style="display: flex; justify-content: space-between; align-items: center;">
        <b-button 
          v-if="nivelActual !== 'regiones'" 
          @click="regresarNivel" 
          style="margin-left: auto;"
        >
        <font-awesome-icon :icon="['fas', 'arrow-left']" /> Regresar
        </b-button>
      </div>
      <h4>Gráficas de miembros activos</h4>
      <div style="height: 400px;">
        <bar-chart :chart-data="datos" :options="opciones" @bar-clicked="handleBarClick" />
      </div>
    </div>
  </template>
  
  <script>
    import BarChart from "../components/BarChart";
    import api from "@/api";

    const Colores = {
        ROJO: 'rgba(255, 99, 132, 0.2)',
        ROJO_BORDE: 'rgb(255, 99, 132)',
        NARANJA: 'rgba(255, 159, 64, 0.2)',
        NARANJA_BORDE: 'rgb(255, 159, 64)',
        AMARILLO: 'rgba(255, 205, 86, 0.2)',
        AMARILLO_BORDE: 'rgb(255, 205, 86)',
        VERDE: 'rgba(75, 192, 192, 0.2)',
        VERDE_BORDE: 'rgb(75, 192, 192)',
        AZUL: 'rgba(54, 162, 235, 0.2)',
        AZUL_BORDE: 'rgb(54, 162, 235)',
        MORADO: 'rgba(153, 102, 255, 0.2)',
        MORADO_BORDE: 'rgb(153, 102, 255)',
        GRIS: 'rgba(201, 203, 207, 0.2)',
        GRIS_BORDE: 'rgb(201, 203, 207)'
    };

    export default {
        name: "Graficas",
        components: { BarChart },
        data() {
            return {
            datos: {         // Datos para Chart.js
                labels: [],   // Etiquetas para el eje X
                datasets: [],  // Los datasets que chart.js usa para graficar
                ids: []
            },
            opciones: {},    // Opciones del gráfico
            nivelActual: 'regiones', // Jerarquía: regiones -> diocesis -> sectores -> actividad
            region_id: null,
            diocesis_id: null,
            sector_id: null,
            actividad_id: null,
            tipo_id: null
            };
        },
        methods: {
            graficar() {
            let url = `reporte/graficas/`;
            if (this.region_id) url += `${this.region_id}/`;
            if (this.diocesis_id) url += `${this.diocesis_id}/`;
            if (this.sector_id) url += `${this.sector_id}/`;
            if (this.actividad_id) url += `${this.actividad_id}/`;

            api.get(url).then((r) => {
                const colors = [
                    Colores.AMARILLO, 
                    Colores.ROJO, 
                    Colores.AZUL,
                    Colores.VERDE, 
                ];

                const borderColors = [
                    Colores.AMARILLO_BORDE, 
                    Colores.ROJO_BORDE, 
                    Colores.AZUL_BORDE, 
                    Colores.VERDE_BORDE, 
                ];

                this.datos = {
                labels: r.data.labels,  // Etiquetas para el eje X
                datasets: r.data.datasets.map((dataset, index) => ({
                    label: dataset.label,
                    data: dataset.data,
                    backgroundColor: colors[index % colors.length],  // Asigna un color a cada dataset
                    borderColor: borderColors[index % borderColors.length],  // Asigna un color de borde a cada dataset
                    borderWidth: 1
                })),
                ids: r.data.ids
                };

                this.opciones = {
                plugins: {
                    title: {
                    display: true,
                    text: r.data.title,
                    },
                },
                scales: {
                    y: {
                    beginAtZero: true,
                    stacked: true
                    },
                    x: {
                    stacked: true
                    }
                },
                responsive: true,
                maintainAspectRatio: false,
                };
            }).catch(error => {
                console.log(error);
            });
            },
            handleBarClick({ dataIndex }) {
            const id = this.datos.ids[dataIndex];

            switch (this.nivelActual) {
                case 'regiones':
                this.nivelActual = 'diocesis';
                this.region_id = id;
                break;
                case 'diocesis':
                this.nivelActual = 'sectores';
                this.diocesis_id = id;
                break;
                case 'sectores':
                this.nivelActual = 'actividades';
                this.sector_id = id;
                break;
                case 'actividades':
                break;
            }

            this.graficar();
            },
            regresarNivel() {
            switch (this.nivelActual) {
                case 'diocesis':
                this.nivelActual = 'regiones';
                this.region_id = null;
                break;
                case 'sectores':
                this.nivelActual = 'diocesis';
                this.diocesis_id = null;
                break;
                case 'actividades':
                this.nivelActual = 'sectores';
                this.sector_id = null;
                break;
            }

            this.graficar();
            }
        },
        mounted() {
            const sector_id = this.$route.params.sector_id ?? null;
            const tipo_id = this.$route.params.tipo_id ?? null;
            const region_id = this.$route.params.region_id ?? null;
            const diocesis_id = this.$route.params.diocesis_id ?? null;

            this.graficar(diocesis_id, region_id, sector_id, tipo_id);
        },
        };
  </script>
  