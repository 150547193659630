import { Chart, registerables } from 'chart.js';

Chart.register(...registerables); // Registro de todos los componentes necesarios

export default {
  props: {
    chartData: {
      type: Object,
      required: true
    },
    opciones: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      localOpciones: { ...this.opciones } // Creamos una copia local de las opciones
    };
  },
  mounted() {
    const canvas = this.$refs.canvas;

    // Verificamos que el canvas existe y tiene contexto 2D
    if (!canvas || !canvas.getContext) {
      console.error("No se pudo obtener el contexto del canvas.");
      return;
    }

    const ctx = canvas.getContext('2d');

    // Configuración de las opciones de la gráfica
    this.localOpciones = {
      ...this.localOpciones, // Mantén las opciones pasadas como props
      scales: {
        y: {
          beginAtZero: true,
          stacked: true // Agrega `stacked` en y si lo necesitas
        },
        x: {
          stacked: true // Agrega `stacked` en x si lo necesitas
        }
      },
      plugins: {
        ...this.localOpciones.plugins,
        datalabels: {
          color: "black",
          textAlign: "center",
          anchor: 'start',
          font: {
            weight: "bold",
            size: 14,
          }
        }
      },
      responsive: true,
      maintainAspectRatio: false,
      hover: {
        mode: 'nearest',
        intersect: true
      },
      interaction: {
        mode: 'index',
        intersect: false
      }
    };

    // Establecemos una altura fija para el canvas a través de CSS
    canvas.style.height = '400px';
    canvas.style.width = '100%'; 

    // Instancia del gráfico
    this.chartInstance = new Chart(ctx, {
      type: 'bar',
      data: this.chartData,
      options: {
        ...this.localOpciones
      }
    });

    canvas.onclick = (evt) => {
      const res = this.chartInstance.getElementsAtEventForMode(
        evt,
        'nearest',
        { intersect: true },
        true
      );
      // If didn't click on a bar, `res` will be an empty array
      if (res.length === 0) {
        return;
      }
      // Alerts "You clicked on A" if you click the "A" chart
      //alert('You clicked on ' + this.chartInstance.data.labels[res[0].index]);
      const dataIndex = res[0].index;
      const datasetIndex = res[0].datasetIndex;
      const data = this.chartInstance.data.datasets[datasetIndex].data[dataIndex];
      
      this.$emit('bar-clicked', { data, dataIndex, datasetIndex });
    }

  },
  watch: {
    chartData(newData) {
      if (this.chartInstance && newData) {
        this.chartInstance.data = newData;
        this.chartInstance.update();
      }
    },
    opciones(newOptions) {
      if (this.chartInstance && newOptions) {
        this.localOpciones = { ...newOptions }; // Actualizamos la copia local cuando cambian las opciones
        this.chartInstance.options = this.localOpciones;
        this.chartInstance.update();
      }
    }
  },
  beforeDestroy() {
    if (this.chartInstance) {
      this.chartInstance.destroy();
    }
  },
  render(h) {
    return h('canvas', { ref: 'canvas' });
  }
};
